import Vue from "vue";
import Xuehua from './components/Xuehua.vue';
import StartupPage from './components/StartupPage.vue';
// let XuehuaCom =  Vue.component("Xuehua",Xuehua)
// let xuehua = new XuehuaCom().$mount()
// document.body.appendChild(xuehua.$el)
// xuehua.show() //显示组件，默认层级最高

import VueParticles from 'vue-particles'

let StartupPageCom =  Vue.component("StartupPage",StartupPage)
let startupPage = new StartupPageCom().$mount()
document.body.appendChild(startupPage.$el)
startupPage.show() //显示组件，默认层级最高


import App from "./App.vue";
import router from "./router";
import Vant, {Dialog} from 'vant';
import 'vant/lib/index.css';
import 'amfe-flexible/index.js'
import "./assets/fonts/iconfont.css";
// import "./assets/fonts/iconfont.js";
import "./assets/css/css.css";
import qs from 'qs'
import axios from 'axios';
import md5 from 'js-md5';
import Clipboard from 'clipboard';
import helper from "./utils/helper";
import api from "./utils/api";
import common from "./utils/common";
import VueI18n from 'vue-i18n';
import VueAwesomeSwiper from 'vue-awesome-swiper'
import "swiper/dist/css/swiper.css";


// xuehua.show() //显示组件，默认层级最高
// xuehua.hide() //隐藏组件



// 请求拦截
axios.interceptors.request.use(config => {
    const token = helper.getToken();
    const lang = helper.getLang();
    if (token) {
        // 在发起请求前，给所有请求的头部添加token
        config.headers.common['Authorization'] = "Bearer " + token;
        config.headers.common['Content-Type'] = 'application/x-www-form-urlencoded';
    }
    if (lang && config.method === 'post' && typeof config.data == 'string') {
        let data = qs.parse(config.data)
        config.data = qs.stringify({
            lang: lang,
            ...data
        })
    } else if (lang) {
        config.params = {
            lang: lang,
            ...config.params
        }
    }
    let baseUri = process.env.VUE_APP_API_BASE_URL + '/buyer';
    // if (config.baseURL === undefined) {
    //     baseUri = helper.getBaseUri()
    //     if (!baseUri) {
    //         baseUri = api.server
    //     }
    // }

    config.url = baseUri + config.url
    // console.log(config);
    return config;
}, error => {
    // 对请求失败做处理
    return Promise.reject(error);
})
axios.interceptors.response.use(res => {
    // 对响应数据做处理
    // console.log("对响应数据做处理")
    const code = res.data.code;
    if (code === -10086) {
        // 判断res.data.res_code 是否是401 如果是则跳转到登录，如果不是则正常返回
        helper.forgetToken();
        location.href = "/#/welcome";
        return Promise.reject({message: res.data.msg});
    }
    return res;
}, error => {
    // 对响应错误做处理
    if (error.message === 'Network Error') {
        // error.message = 'The network connection is interrupted, please refresh and try again'
    }
    return Promise.reject(error);
})


var load = async function () {
    var defaultTitle = 'loading'
    await axios.get(api.get_config).then(res => {
        if (res.data.code == 0) {
            var data = res.data.data;
            for (const key in data) {
                if (key == 'app.title') {
                    document.title = defaultTitle = data[key]
                }
                localStorage.setItem(key, data[key]);
            }
        }
    });

    Vue.use(Vant);
    Vue.use(VueAwesomeSwiper)
    Vue.use(VueParticles)
    //多语言
    var messages = {}
    var i18n_messages = localStorage.getItem('i18n_messages');
    if (i18n_messages) {
        messages = JSON.parse(i18n_messages)
    }
    var i18n_lang = helper.getLang();
    Vue.use(VueI18n)

    const i18n = new VueI18n({
        locale: i18n_lang ? i18n_lang : 'idn', // 设置语言环境
        fallbackLocale: 'idn',
        silentTranslationWarn: true,
        messages // 设置语言环境信息
    })
    await axios.get(api.get_i18n_messages).then(res => {
        if (res.data.code == 0) {
            messages = res.data.data;
            for (const lang in messages) {
                // console.log(lang, messages[lang])
                i18n.mergeLocaleMessage(lang, messages[lang])
            }
            if (res.data.locale && !i18n_lang) {
                i18n.locale = res.data.locale
                helper.setLang(res.data.locale);
            }
            if (res.data.fallbackLocale) {
                i18n.fallbackLocale = res.data.fallbackLocale
            }
            localStorage.setItem('i18n_messages', JSON.stringify(messages));
        }
    });

    axios.defaults.crossDomain = true;
    axios.defaults.withCredentials = true;


    Vue.prototype.$qs = qs;
    Vue.prototype.$axios = axios;
    Vue.prototype.$helper = helper;
    Vue.prototype.$api_url = api;
    Vue.prototype.$clipboard = Clipboard;
    Vue.prototype.$dialog2 = Dialog;
    Vue.prototype.$md5 = md5;
    Vue.prototype.$encrypt_key = "";

    common.install(Vue, {
        $axios: axios,
        $api_url: api,
    });

    Vue.use(Vant);

    Vue.config.productionTip = false;

    router.beforeEach((to, from, next) => {
        var title = localStorage.getItem("app.title");
        document.title = title ? title : defaultTitle
        next()
    })
    window._vue = new Vue({
        router,
        i18n,
        render: h => h(App)
    });
    startupPage.hide() //显示组件，默认层级最高
    // xuehua.hide()
    window._vue.$mount("#app");

};
//为了必须先获取服务端的config和lang messages配置信息
load().then(r => {
    console.log('OK');
});