<template>
    <div id="app">
        <router-view/>
        <template v-if="is_show">
            <Service/>
        </template>
    </div>
</template>

<script>
    import Service from "./components/Service";
    export default {
        name: 'App',
        components: {Service},
        data() {
            return {
                is_show: true
            }
        },
        methods: {
            setCapableTitle() {
                var image = document.getElementById("ogIMage");
                var title = document.getElementById("ogTitle");
                var desc = document.getElementById("ogDescription");
                let webUrl = process.env.VUE_APP_WEB_BASE_URL + '/img.png';
                image.setAttribute("content", webUrl);
                title.setAttribute("content", this.$t('DeepSpeed'));
                desc.setAttribute("content", this.$t('DeepSpeed'));
            },
        },
        mounted() {
            // console.log(window.location);
            this.setCapableTitle()
            console.log(this.$route.name);
            let str = window.location.hash.substring(0,10);
            if (this.$route.name == 're-error' || this.$route.name == 're-page' || str == '#/re-page?' || str == '#/re-error') {
                this.is_show = false;
            }
        }
    }
</script>