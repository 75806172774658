export default {
    install(Vue, options) {
        this.options = options
        this.$axios = options.$axios
        this.$api_url = options.$api_url
        Vue.prototype.getUser = (parameter) => {
            return this.$axios({
                url: this.$api_url.user,
                method: 'get',
                params: parameter
            })
        }
        Vue.prototype.splitNumber = (val, precision) => {
            precision = precision || 6
            let result = (parseFloat(val).toFixed(precision)).split('.')
            // console.log('splitNumber', val, precision, result)
            return result
        }
    }
}

