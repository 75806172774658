<template>
	<div>
		<van-tabbar v-model="activeIndex" :fixed="true" :placeholder="true">
			<van-tabbar-item to="/">
				<div class="ico ico-1"></div>
				<div class="item-label">{{$t('首页')}}</div>
			</van-tabbar-item>
			<van-tabbar-item to="/revenue">
				<div class="ico ico-2"></div>
				<div class="item-label">{{$t('收益记录')}}</div>
			</van-tabbar-item>
			<van-tabbar-item to="/trend">
				<div class="ico ico-3"></div>
				<div class="item-label">{{$t('实时行情')}}</div>
			</van-tabbar-item>
			<van-tabbar-item to="/my">
				<div class="ico ico-4"></div>
				<div class="item-label">{{$t('资产')}}</div>
			</van-tabbar-item>
		</van-tabbar>
	</div>
</template>

<script>
	export default {
		name: 'Foot',
		props: {
			active: {
				type: Number,
				default: 0
			}
		},
		components:{},
		computed: {
			activeIndex: {
				get: function () {
					return this.active;
				},
				set: function () {
				}
			}
		},
		data: function() {
			return {}
		},
	}
</script>
<style>
	.ico{
		margin: 0 auto;
		width: 26px;
		height: 26px;
		background-repeat: no-repeat;
		background-position: center;
		background-size: contain;
	}
	.ico-ac{
		margin: 0 auto;
		width: 18px;
		height: 18px;
		display: none;
		margin-bottom: 6px;
	}
	.ico-ac-home{
		width: 36px;
		height: 36px;
		display: none;
		margin-bottom: 0;
	}
	.ico-home{
		margin: 0 auto;
		width: 36px;
		height: 36px;
		background-repeat: no-repeat;
		background-position: center;
		background-size: contain;
	}
	.ico-1{
		background-image: url(../assets/pictures/foot1.png);
	}
	.ico-2{
		background-image: url(../assets/pictures/foot2.png);
	}
	.ico-3{
		background-image: url(../assets/pictures/foot3.png);
	}
	.ico-4{
		background-image: url(../assets/pictures/foot4.png);
	}

	.van-tabbar-item--active .ico-1{
		background-image: url(../assets/pictures/foot1_on.png);
	}
	.van-tabbar-item--active .ico-2{
		background-image: url(../assets/pictures/foot2_on.png);
	}
	.van-tabbar-item--active .ico-3{
		background-image: url(../assets/pictures/foot3_on.png);
	}
	.van-tabbar-item--active .ico-4{
		background-image: url(../assets/pictures/foot4_on.png);
	}
	.van-tabbar-item--active .ico-ac{
		display: block;
	}
	.van-tabbar-item--active .ico-home{
		display: none;
	}
	.van-tabbar-item__icon{
		display: none;
	}
	.van-tabbar::after {
		display: none;
	}
	.van-tabbar .iconfont{
		color: #a8bab4;
		font-size: 20px;
	}
	.van-tabbar .item-label{
		color: #50646f;
		font-size: 12px;
		word-break: break-all;
		margin-top:5px;
	}
	.van-tabbar-item--active{
		background-color: transparent!important;
	}
	.van-tabbar-item{
		position: relative;
	}
	.van-tabbar .van-tabbar-item--active div{
		color: #56d6f1;
	}
	.van-tabbar__placeholder {
		height: 70px!important;
	}
	.van-tabbar{
		background-color: #2b3640!important;
		height: 70px!important;
	}

</style>