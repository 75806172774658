<template>
    <div class="serve-popup">
        <vue-draggable-resizable
                v-if="whs.length > 0"
                :w="64" :h="64"
                :resizable="false"
                class-name="whs-service">
            <!-- <div class="whs-service-btn" @click="openService">
                <img src="../assets/image/new/55.png" alt="">
            </div> -->
        </vue-draggable-resizable>
        <van-popup
            round
                v-model="show"
                position="bottom"
        >
            <van-cell-group :title="$t('在线客服')">
                <van-cell
                        v-for="(item,i) in whs"
                        :key="`whs${i}`"
                        is-link
                        :title="item.label"
                        :url="item.url"
                        center
                        icon="chat-o"
                >
                    <template #icon>
                        <a :href="item.url" target="_blank" class="tag-icon">
                            <img :src="item.tag" width="24" height="24"/>
                        </a>
                    </template>
                    <template #title>
                        <template v-if="item.tag == 'copy'">
                            <div style="width: 100%">{{$t(item.label)}}</div>
                        </template>
                        <template v-else>
                            <a :href="item.url" target="_blank">
                                <div v-if="i == 0" style="color: #ffffff;font-weight:bold;width: 100%">{{$t(item.label)}}</div>
                                <div v-else style="color: #ffffff;width: 100%">{{$t(item.label)}}</div>
                            </a>
                        </template>
                    </template>
                    <template #right-icon>
                        <template v-if="item.tag == 'copy'">
                            <van-button style="height: 22px;max-width: 88px"
                                        :class="'btn-copy copy_' + $md5.update(item.url)"
                                        :data-clipboard-text="item.url"
                                        @click="copy('.copy_' + $md5.update(item.url))"
                            >{{$t('copy')}}
                            </van-button>
                        </template>
                        <template v-else>
                            <a :href="item.url" target="_blank" style="color: #ffffff;">
                                <van-icon name="arrow"/>
                            </a>
                        </template>
                    </template>
                </van-cell>
            </van-cell-group>
        </van-popup>
    </div>
</template>

<script>
    import VueDraggableResizable from 'vue-draggable-resizable';
    import 'vue-draggable-resizable/dist/VueDraggableResizable.css';

    export default {
        name: "Service",
        components: {VueDraggableResizable},
        data() {
            return {
                whs: [],
                show: false,
            };
        },
        methods: {
            openService() {
                this.show = true;
            },
            getWhs() {
                let whs = localStorage.getItem("app.whatsapp_service");
                if (whs === null) {
                    setTimeout(() => {
                        this.getWhs()
                    }, 300);
                    return;
                }
                if (whs) {
                    this.whs = JSON.parse(whs);
                }
            }
        },
        mounted() {
            this.$nextTick(() => {
                this.getWhs()
            });
            let that = this;
            window._vue.$on('getWhs', () => {
                that.getWhs();
            })
            window._vue.openService = this.openService;
        }
    };
</script>
<style scoped>
    .whs-service {
        border: 0;
        position: fixed;
        bottom: 10%;
        right: 5%;
        touch-action: none;
        z-index: 9999 !important;
    }

    .whs-service-btn{
        padding: 5px;
        cursor: pointer;
        position: fixed;
        z-index: 9999;
        right: 15px;
        bottom: 12%;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 55px;
        height: 55px;
        border-radius: 430px;
        box-shadow: 1px 3px 10px #1977FB;
        background: linear-gradient(144deg, #36AFFD, #1977FB);
    }

    .serve-popup ::v-deep .van-popup{
        background-color: #2b3640;
    }
    .serve-popup ::v-deep .van-cell-group__title{
        color: #ffff;
        text-align: center;
        font-size: 16px;
        padding: 20px;
    }
    .serve-popup ::v-deep .van-cell{
        background-color: #394d58;
        border-radius: 10px;
        color: #ffff;
        padding:15px;
        margin-bottom: 10px;
    }
    .serve-popup ::v-deep .van-cell::after{
        display: none;
    }
    .tag-icon{
        display: block;
        line-height: 1;
        border: 2px solid #45a3d4;
        border-radius: 30px;
        width: 40px;
        height: 40px;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-right: 10px;
    }
    .serve-popup ::v-deep .van-cell-group{
        padding: 15px;
        background-color: transparent;
    }





</style>